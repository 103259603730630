<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
      <b-card :title="'Virement vers le compte de '+user.name+' '+user.surname">
        
         <b-row>
        <b-col v-if="user.stripe_id"
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group 
            label="Montant (€)"
            label-for="basicInput"
          >
          
            <b-form-input
             required
              id="basicInput"
              type="text"
              v-model="form.amount"
          
              placeholder="Montant (€)"
             
            />
        
          </b-form-group>
        </b-col>

      </b-row>
    
       <b-button 
        @click="createTransfer"
        >
        Effectuer le virement
        </b-button>
      </b-card>
  </div>
</b-overlay>
</template>

<script>
import {BModal,BOverlay, BButton, VBModal, BCard, BCol, BRow, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Auth, Woocommerce} from '@simb2s/senseye-sdk'
import ScanPopup from '../../components/ScanPopup.vue'
import { VueGoodTable } from 'vue-good-table'
import UserForm from './UserForm.vue'
import emitter from 'tiny-emitter/instance'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BOverlay,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton, 
    BCol,
    BRow,
    UserForm,
    ScanPopup,
    PrescriptionPopup
  },
  data() {
    return {
      user:null,
      form:{amount:0},
      loaded:false,
      loading:true,
     
    }
  },
  methods:{
    async createTransfer() {

      this.loading=true
      try {
        await APIRequest.create_stripe_transfer(this.user.stripe_id,this.form.amount*100)
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Le virement a bien été effectué !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.loading=false

      } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }

    },
   
   
  },
  async mounted()
  {
    


    this.user=await Auth.get_user_with_id(this.$route.params.id)
    console.log('userr')
    console.log(this.user)
    
    this.loaded=true
    this.loading=false
  }
}
</script>
